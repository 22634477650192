import React from "react";
import Calculator from "../components/Calculator";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { CheckIcon } from "@heroicons/react/outline";
import { graphql } from "gatsby";
import OutSourceButton from "../components/OutSourceButton";
import BlockContent from "../components/BlockContent";

const SplitTextBox = ({
  heading1,
  text1,
  heading2,
  text2,
  helpCheck1,
  helpCheck2,
  helpCheck3,
}) => {
  return (
    <>
      <div className="grid md:grid-cols-2 grid-cols-1 text-center pb-8">
        <div className="bg-white text-gray-dark p-4 shadow-md">
          <div className="grid place-content-center">
            <svg
              width="33"
              height="32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.733 22.667c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 19.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Zm-12 0c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 7.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Z"
                fill="#0FF"
              />
            </svg>
          </div>
          <div className=" text-heading-4 md:text-heading-2 mb-2 py-8">
            {heading1}
          </div>
          <div>
            <BlockContent blocks={text1} />
          </div>
        </div>

        <div className="bg-gray-dark text-gray-light p-4 shadow-md border-2 border-accent">
          <div className="grid place-content-center">
            <svg
              width="33"
              height="32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.733 22.667c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 19.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Zm-12 0c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 7.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Z"
                fill="#0FF"
              />
            </svg>
          </div>
          <div className="text-heading-4 md:text-heading-2 mb-2 py-8">
            {heading2}
          </div>
          <div>
            <BlockContent blocks={text2} />
          </div>
        </div>
      </div>
      <div className="flex justify-center pb-8">
        <div className="md:w-5/6">
          <Calculator
            exampleInterest={0.092}
            startPrice={990}
            htmlInterest={"9,2"}
          />
        </div>
      </div>

      <div className="text-heading-4 px-24 text-center">
        Fordeler ved Ryddi Hjelp
      </div>
      <div className="py-8 place-content-center items-center flex flex-col">
        <div className="md:w-1/2 w-full place-content-center bg-white shadow-md p-4 content-center">
          <div className="flex flex-row">
            <div className="pr-2">
              <svg
                width="33"
                height="32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.733 22.667c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 19.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Zm-12 0c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 7.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Z"
                  fill="#0FF"
                />
              </svg>
            </div>
            {helpCheck1}
          </div>

          <div className="flex flex-row">
            <div className="pr-2">
              <svg
                width="33"
                height="32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.733 22.667c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 19.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Zm-12 0c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 7.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Z"
                  fill="#0FF"
                />
              </svg>
            </div>
            {helpCheck2}
          </div>

          <div className="flex flex-row">
            <div className="pr-2">
              <svg
                width="33"
                height="32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.733 22.667c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 19.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Zm-12 0c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 7.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Z"
                  fill="#0FF"
                />
              </svg>
            </div>
            {helpCheck3}
          </div>
        </div>
      </div>
      <div className="flex place-content-center">
        <OutSourceButton />
      </div>
    </>
  );
};

const Hjelp = ({
  data: {
    sanityHelpPage: {
      helpHeading,
      helpTextHeading1,
      helpTextBoxContent1,
      helpTextHeading2,
      helpTextBoxContent2,
      helpCheck1,
      helpCheck2,
      helpCheck3,
    },
  },
}) => {
  return (
    <>
      <Nav />
      <div className="px-8">
        <div className="text-heading-4 md:text-heading-2 text-gray-dark mb-2 pt-16 pb-8">
          {helpHeading}
        </div>

        <SplitTextBox
          heading1={helpTextHeading1}
          heading2={helpTextHeading2}
          text1={helpTextBoxContent1}
          text2={helpTextBoxContent2}
          helpCheck1={helpCheck1}
          helpCheck2={helpCheck2}
          helpCheck3={helpCheck3}
        />
      </div>

      <Footer />
    </>
  );
};

export const query = graphql`
  query {
    sanityHelpPage(_id: { regex: "/helpPage$/" }) {
      helpHeading
      helpTextHeading1
      helpTextBoxContent1: _rawHelpTextBoxContent1
      helpTextHeading2
      helpTextBoxContent2: _rawHelpTextBoxContent2
      helpCheck1
      helpCheck2
      helpCheck3
    }
  }
`;

export default Hjelp;

/* 
a.	Overskriftboks
b.	Tekstboks som leder til kalkulator for vanlig lån (Se insta)
c.	Kalkulator
d.	Tekstboks
e.	Checkbokser
*/
